












import { Component, Vue } from 'vue-property-decorator';
import { facade } from 'vue-input-facade';

@Component({
  directives: {
    facade,
  },
})
export default class DadosTelefone extends Vue {
  public rules = [
    (v: string) => !!v || 'DDD é Obrigatório',
    (v: string) => v.length >= 9 || 'DDD incorreto',
  ];

  public telMask = '#####-######';

  get ddd(): string {
    return this.$store.state.cotacao.ddd;
  }
  get telefone() {
    return this.$store.state.cotacao.telefone;
  }

  set telefone(value) {
    // console.log('set: ' + value);
    const firstChar = value.substring(0, 1);
    if (this.ddd.length < 2 && value.length >= 10 && firstChar !== '9') {
      setTimeout(() => {
        this.$store.commit('SET_DDD', value.substring(0, 2));
        this.$store.commit('SET_TELEFONE', value.substring(2));
      }, 100);
    } else {
      this.$store.commit('SET_TELEFONE', value);
    }
  }

  get errorTelefone() {
    return this.$store.state.errors.telefone;
  }

  public onChange() {
    this.$store
      .dispatch('VALIDAR_DADOS')
      .then()
      .catch(() => {
        return;
      });
  }
}
