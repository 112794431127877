










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DadosNome extends Vue {
  public rules = [(v: string) => !!v || 'Nome é obrigatório'];

  get nome() {
    return this.$store.state.cotacao.nome;
  }

  set nome(value) {
    this.$store.commit('SET_NOME', value);
  }

  get errorNome() {
    return this.$store.state.errors.nome;
  }

  public onChange() {
    this.$store
      .dispatch('VALIDAR_DADOS')
      .then()
      .catch(() => {
        return;
      });
  }
}
