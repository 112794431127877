












import { Component, Vue } from 'vue-property-decorator';
import * as EmailValidator from 'email-validator';

@Component
export default class DadosEmail extends Vue {
  public rules = [
    (v: string) => EmailValidator.validate(v) || 'Email é obrigatório',
  ];

  get email() {
    return this.$store.state.cotacao.email;
  }

  set email(value) {
    this.$store.commit('SET_EMAIL', value);
  }

  get errorEmail() {
    return this.$store.state.errors.email;
  }

  public onChange() {
    this.$store
      .dispatch('VALIDAR_DADOS')
      .then()
      .catch(() => {
        return;
      });
  }
}
