












import { Component, Vue } from 'vue-property-decorator';
import { facade } from 'vue-input-facade';

@Component({
  directives: {
    facade,
  },
})
export default class DadosDdd extends Vue {
  public rules = [
    (v: string) => !!v || 'DDD é Obrigatório',
    (v: string) => v.length === 2 || 'DDD incorreto',
  ];

  public dddMask = '##';

  get ddd() {
    return this.$store.state.cotacao.ddd;
  }

  set ddd(value) {
    this.$store.commit('SET_DDD', value);
  }

  get errorDDD() {
    return this.$store.state.errors.ddd;
  }

  public onChange() {
    this.$store
      .dispatch('VALIDAR_DADOS')
      .then()
      .catch(() => {
        return;
      });
  }
}
